/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 14px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}
img {
    max-width: 100%;
    display: block;
}
a {
    outline: 0!important;
}

body {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
}
.center {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    position: relative;
    .top_top {
        widows: 100%;
        height: 45px;
        background: #efefef;
        p {
            float: left;
            line-height: 45px;
            font-size: 14px;
            color: #666;
        }
        .pc_yuyan {
            float: right;
            span {
                font-size: 14px;
                line-height: 45px;
                padding-left: 40px;
                float: left;
                margin-left: 30px;
                &:first-child {
                    margin-left: 0;
                    padding-left: 35px;
                    background: url(../img/china.jpg) no-repeat left center;
                }
                &:first-child +span {
                    background: url(../img/eng.jpg) no-repeat left center;
                }
            }
        }
    }
    .head_top {
        width: 100%;
        background: #fff;
        .fl {
            a {
                float: left;
                img {
                    height: 130px;
                    display: block;
                }
            }
        }
        .fr {
            margin-top: 37px;
            .tel {
                float: right;
                p {
                    padding-left: 60px;
                    background: url(../img/dianh.png) no-repeat left center;
                    i {
                        font-size: 14px;
                        color: #666;
                        display: block;
                        font-style: normal;
                    }
                    span {
                        display: block;
                        color: #000;
                        font-size: 25px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.nav {
    width: 100%;
    background: #434343;
    height: 60px;
    ul {
        li {
            float: left;
            width: 11.11%;
            height: 60px;
            position: relative;
            a {
                width: 100%;
                text-align: center;
                line-height: 60px;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: #dc323b;
                }
                &.active {
                    background: #dc323b;
                }
            }
            .v_list {
                position: absolute;
                width: 100%;
                top: 60px;
                background: #434343;
                left: 0;
                z-index: 99;
                display: none;
                a {
                    color: #fff;
                    line-height: 36px;
                    font-size: 14px;
                    transition: .5s;
                    &:hover  {
                        transition: .5s;
                        background: #dc323b;
                    }
                }
            }
            &:hover .v_list {
                display: block;
            }
        }
    }
}

.fixed {
    position: fixed;
    max-width: 1920px;
    top: 0;
    z-index: 10000;
    float: none !important;
}

.pc_banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .pcbanner {
        position: relative;
        .swiper-slide {
            width: 100%;
            display: block;
        }
        .prev {
            cursor: pointer;
            outline: 0;
            left: 74px;
            width: 48px;
            height: 86px;
            position: absolute;
            top: 42%;
            background: url(../img/bannerle.png) no-repeat;
            background-size: 100%;
            z-index: 4;
        }
        .next {
            cursor: pointer;
            outline: 0;
            right: 74px;
            width: 48px;
            height: 86px;
            position: absolute;
            top: 42%;
            background: url(../img/bannerri.png) no-repeat;
            background-size: 100%;
            z-index: 4;
        }
        .pagination {
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: 9;
            bottom: 10px;
            overflow: hidden;
            span {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #fff;
                margin: 0 3px;
                opacity: 1;
                outline: 0;
                &.swiper-pagination-bullet-active {
                    background: #dc323b;
                }
            }
        }
    }
}

.m_header {
    width: 100%;
    display: none;
    position: relative;
    .m_head_logo {
        width: 100%;
        background: #fff;
        img {
            max-width: 100%;
            height: 80px;
            display: block;
            margin: 0 auto;
        }
    }
    .m_head_content {
        height: .36rem;
        background: #dc323b;
        width: 100%;
        z-index: 99;
        box-shadow: 0px 5px 10px 1px #999;
        position: relative;
        span {
            font-size: 16px;
            color: #fff;
            float: left;
            line-height: .36rem;
            padding-left: 12px;
        }
        p {
            float: right;
            padding-right: .2rem;
            img {
                height: .16rem;
                margin-top: .1rem;
            }
        }
        .menu {
            float: right;
            width: .36rem;
            margin-right: .1rem;
            height: .36rem;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .36rem;
                display: block;
            }
        }
    }
    .fixed {
        position: fixed;
    }   
}

.m_banner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: none;
    .mbanner {
        width: 100%;
        position: relative;
        .swiper-slide {
            width: 100%;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .pagination {
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 9;
        bottom: 5px!important;
        overflow: hidden;
        span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            overflow: hidden;
            cursor: pointer;
            background: #fff;
            margin: 0 3px;
            opacity: 1;
            outline: 0;
            &.swiper-pagination-bullet-active {
                background: #dc323b;
            }
        }
    }
}

.m_menu {
    position: fixed;
    left: -50%;
    top: 0;
    width: 50%;
    height: 100%;
    background: #333436;
    .app_menu {
        width: 100%;
        padding-top: .45rem;
        ul {
            li {
                width: 100%;
                height: 0.35rem;
                line-height: 0.35rem;
                position: relative;
                border-bottom: 1px solid #282827;
                right: 0;
                background-size: auto 0.3rem;
                a {
                    display: block;
                    width: 100%;
                    color: #eee;
                    font-size: 0.11rem;
                    text-align: center;
                }
                &:hover {
                    background: #252629;
                }
                &.on {
                    background: #252629;
                }
            }
        }
    }
}

.index_wrap {
    width: 100%;
    display: none;
}

.hot_ci {
    height: 80px;
    background: #e7e7e7;
    .text {
        .fl {
            p {
                line-height: 80px;
                span {
                    font-size: 18px;
                    color: #dc323b;
                    display: block;
                    font-weight: bold;
                    float: left;
                }
                a {
                    float: left;
                    font-size: 14px;
                    color: #000;
                    margin-left: 15px;
                    &:nth-of-type(1) {
                        margin-left: 5px;
                    }
                }
            }
        }
        .fr {
            position: relative;
            width: 280px;
            height: 50px;
            margin-top: 20px;
            input {
                outline: 0;
                border: none;
            }
            .inp1 {
                width: 280px;
                height: 40px;
                border: 1px solid #dc323b;
                border-radius: 5px;
                background: none;
                padding-left: 15px;
                font-size: 16px;
            }
            .inp2 {
                position: absolute;
                right: 0;
                top: 0;
                width: 65px;
                height: 40px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background: url(../img/sea.png) no-repeat center #dc323b;
                background-size: 19px;
            }
        }
    }
}

.container {
    width: 100%;
    overflow: hidden;
}

.main {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.box_tit {
    position: relative;
    p {
        line-height: 212px;
        color: #efefef;
        font-family: impact;
        font-size: 126px;
        text-align: center;
        text-transform: uppercase;
    }
    span {
        position: absolute;
        line-height: 60px;
        font-size: 45px;
        color: #222;
        top: 80px;
        font-weight: bold;
        display: block;
        width: 100%;
        height: 60px;
        text-align: center;
        i {    
            line-height: 60px;
            font-size: 45px;
            color: #dc323b;
            font-style: normal;
        }
    }
    em {
        position: absolute;
        line-height: 25px;
        font-size: 20px;
        color: #222222;
        top: 145px;
        font-weight: 100;
        display: block;
        width: 100%;
        height: 25px;
        text-align: center;
        font-style: normal;
    }
}

.i_box2 {
    width: 100%;
    padding: 20px 0;
    .box {
        width: 100%;
        margin-top: 30px;
        ul {
            li {
                width: 23%;
                margin-left: 2.66%;
                float: left;
                position: relative;
                .pic {
                    width: 250px;
                    height: 250px;
                    margin: 0 auto;
                    overflow: hidden;
                    border-radius: 125px;
                    img {
                        transform: scale(1);
                        transition: .5s;
                    }
                }
                span {
                    display: block;
                    text-align: center;
                    line-height: 58px;
                    font-size: 18px;
                    text-align: center;
                    margin: 0 auto 10px;
                    background: url(../img/line.jpg) no-repeat center bottom;
                }
                p {
                    line-height: 25px;
                    font-size: 14px;
                    color: #666666;
                    text-align: justify;
                    width: 90%;
                    margin: 0 auto;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover {
                    .pic {
                        img {
                            transform: scale(1.1);
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

.i_box1 {
    width: 100%;
    margin-top: 50px;
    background: url(../img/bg1.jpg) no-repeat center 0;
    padding-bottom: 50px;
}

.ml_tit {
    display: block;
    width: 100%;
    height: 70px;
    padding-top: 10px;
    background: #434343;
    text-align: center;
    span {
        font-size: 25px;
        color: #fff;
        line-height: 30px;
        font-weight: normal;
    }
    p {
        font-size: 16px;
        color: #b2b2b2;
        line-height: 20px;
        text-transform: uppercase;
        display: block;
        margin-top: 5px;
    }
}

.main_l {
    width: 24%;
    padding-bottom: 20px;
    .text {
        width: 100%;
        ul {
            width: 100%;
            background: #fff;
            li {
                display: block;
                a {
                    display: block;
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 16px;
                    text-indent: 49px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: url(../img/cpyj.png) no-repeat 19px 17px;
                    border-bottom: 1px solid #e3e3e3;
                    font-weight: bold;
                }
            }
        }
    }
    .main_contact {
        margin-top: 20px;
        background: #fff;
        .cont_contact {
            padding: 15px 0;
            p {
                font-size: 14px;
                color: #666;
                line-height: 24px;
                padding: 0 5%;
                margin-bottom: 8px;
            }
        }
    }
}

.main_r {
    width: 74%;
    overflow: hidden;
}

.box1_js {
    background: #fff;
    padding: 40px 60px;
    position: relative;
    .swiper-container {
        width: 100%;
        overflow: hidden;
    }
    .swiper-slide {
        .s_box {
            .pic {
                width: 50%;
                float: left;
                height: 295px;
                border: 1px solid #ddd;
                overflow: hidden;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                }
            }
            .text {
                float: right;
                width: 50%;
                padding-left: 20px;
                span {
                    width: 100%;
                    height: 52px;
                    line-height: 52px;
                    border-bottom: 2px solid #d7d7d7;
                    display: block;
                    overflow: hidden;
                    font-size: 22px;
                    font-weight: bold;
                }
                p {    
                    width: 100%;
                    max-height: 125px;
                    overflow: hidden;
                    margin: 20px 0;
                    font-size: 14px;
                    color: #333;
                    line-height: 25px;
                    text-align: justify;
                }
                i {
                    display: block;
                    width: 136px;
                    height: 31px;
                    background: #434343;
                    line-height: 31px;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    &:hover {
                        background: #dc323b;
                        color: #fff;
                    }
                }
            }
        }
    }
    .next {
        cursor: pointer;
        outline: 0;
        left: 0px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        background: url(../img/ble.gif) no-repeat center;
        z-index: 4;
    }
    .prev {
        cursor: pointer;
        outline: 0;
        right: 0px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        background: url(../img/bri.gif) no-repeat center;
        z-index: 4;
    }
}

.box1_list {
    margin-top: 20px;
    ul {
        li {
            width: 31.33%;
            margin-right: 3%;
            margin-bottom: 2%;
            float: left;
            box-shadow: 0px 1px 5px 1px #ddd;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .imgauto {
                width: 100%;
                height: 209px;
                background: #fff;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 14px;
                line-height: 24px;
                color: #333;
                display: block;
                text-align: center;
                padding: 8px 0;
                font-weight: bold;
                background: #b2b2b2;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            &:hover {
                .imgauto img {
                    transform: scale(1.1);
                    transition: .5s;
                }
                span {
                    background: #dc323b;
                    color: #fff;
                }

            }
        }
    }
}

.i_box0 {
    padding: 10px 0 50px 0;
    background: url(../img/bg2.jpg) no-repeat center 0;
    .center {
        width: 1400px;
    }
    .box_tit {
        span {
            color: #fff;
            i {
                color: #c9a872;
            }
        }
        em {
            color: #fff;
        }
        p {
            color: #343434;
        }
    }
}

.box0_js {
    padding: 0 100px;
    position: relative;
    margin-top: 30px;
    .swiper-container {
        width: 100%;
        overflow: hidden;
    }
    .swiper-slide {
        .pic {
            width: 100%;
            padding: 10px;
            .imgauto {
                background: #fff;
                float: left;
                height: 295px;
                overflow: hidden;
                border: 10px solid #fff;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                }
            }
        }
    }
    .next {
        cursor: pointer;
        outline: 0;
        left: 0px;
        width: 68px;
        height: 68px;
        position: absolute;
        top: 50%;
        margin-top: -34px;
        background: url(../img/le3.png) no-repeat center;
        z-index: 4;
    }
    .prev {
        cursor: pointer;
        outline: 0;
        right: 0px;
        width: 68px;
        height: 68px;
        position: absolute;
        top: 50%;
        margin-top: -34px;
        background: url(../img/ri3.png) no-repeat center;
        z-index: 4;
    }
}

.i_box4 {
    width: 100%;
    padding: 10px 0;
    .list {
        width: 100%;
        margin-top: 30px;
        .text1 {
            width: 65%;
            float: left;
        }
        .text2 {
            width: 35%;
            float: right;
            padding-left: 3%;
        }
        .t_tit {
            height: 60px;
            background: url(../img/xw1.jpg) no-repeat 0 bottom;
            margin-bottom: 35px;
            span {
                line-height: 50px;
                font-weight: bold;
                font-size: 18px;
                float: left;
            }
            a {
                float: right;
                width: 55px;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                color: #666;
                background: url(../img/more.gif) no-repeat right center;
            }
        }
        .t_box {
            .fl {
                width: 50%;
                padding-right: 3%;
            }
            .fr {
                width: 50%;
                padding-left: 3%;
            }
        }
        .gs_text {
            span {
                display: block;
                height: 60px;
                line-height: 60px;
                width: 100%;
                overflow: hidden;
                border-bottom: 1px solid #e1e1e1;
                font-weight: bold;
                font-size: 16px;
            }
            p {
                line-height: 30px;
                font-size: 14px;
                color: #555;
                height: 120px;
                overflow: hidden;
                margin: 12px 0;
            }
            i {
                display: block;
                width: 108px;
                height: 34px;
                background: #8f8c8b;
                text-align: center;
                font-size: 14px;
                color: #fff;
                line-height: 34px;
                font-style: normal;
                &:hover {
                    background: #dc323b;
                }
            }
        }
        .hy_text {
            ul {
                li {
                    position: relative;
                    margin-top: 6px;
                    i {
                        width: 22px;
                        height: 22px;
                        background: #8f8c8b;
                        color: #fff;
                        text-align: center;
                        line-height: 22px;
                        margin-top: 5px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-style: normal;
                    }
                    span {
                        display: block;
                        width: 95%;
                        height: 34px;
                        line-height: 34px;
                        overflow: hidden;
                        font-size: 14px;
                        padding-left: 32px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    &:hover {
                        i {
                            background: #dc323b;
                        }
                        span {
                            color: #dc323b;
                        }
                    }
                }
            }
        }
    }
    .que_list {
        width: 100%;
        ul {
            li {
                color: #545455;
                line-height: 21px;
                margin-bottom: 32px;
                a {
                    display: block;
                    background: url(../img/wen.gif) no-repeat left center;
                    padding-left: 28px;
                    color: #333;
                    font-weight: bold;
                    font-size: 14px;
                }
                p {
                    background: url(../img/da.gif) no-repeat left 2px;
                    padding-left: 28px;
                    margin-top: 10px;
                    line-height: 22px;
                    color: #555;
                    font-size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.i_box5 {
    width: 100%;
    margin-top: 50px;
    .box {
        width: 100%;
        background: #f1f1f1;
        margin-top: 30px;
        .fl {
            width: 50%;
            padding: 0 90px;
            padding-top: 40px;
            float: right;
            h3 {    
                font-size: 26px;
                font-weight: bold;
                line-height: 80px;
                margin-bottom: 20px;
                background: url(../img/line2.jpg) no-repeat 0 bottom;
            }
            i {}
            p {
                color: #555;
                line-height: 30px;
                font-size: 14px;
            }
            a {
                display: block;
                width: 108px;
                height: 34px;
                background: #dc323b;
                text-align: center;
                font-size: 14px;
                color: #fff;
                line-height: 34px;
                font-style: normal;
                margin-top: 20px;
            }
        }
        .fr {
            width: 50%;
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.i_box6 {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    .t_tit {
        height: 60px;
        background: url(../img/xw1.jpg) no-repeat 0 bottom;
        margin-bottom: 35px;
        span {
            line-height: 50px;
            font-weight: bold;
            font-size: 18px;
            float: left;
        }
        a {
            float: right;
            width: 55px;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            color: #666;
            background: url(../img/more.gif) no-repeat right center;
        }
    }
    .box {
        width: 100%;
        .swiper-slide {
            width: 25%;
            float: left;
            background: #fff;
            float: left;
            padding: 10px;
            .imgauto {
                width: 100%;
                height: 383px;
                border: 1px solid #ddd;
            }
        }
    }
}

.i_boxnav {
    border-top: solid 1px #e0dede;
    line-height: 49px;
    text-align: center;
    a {
        width: 11.11%;
        float: left;
        font-size: 16px;
        color: #686868;
        &:hover {
            color: #dc323b;
        }
    }
}

.footer {
    width: 100%;
    padding: 30px 0;
    background: #292c31;
    position: relative;
    .foot_tit {
        margin-bottom: 20px;
        span {
            color: #abacad;
            font-size: 20px;
            font-weight: bold;
            display: block;
        }
        i {
            font-size: 16px;
            font-weight: 100;
            color: #abacad;
            font-style: normal;
            display: block;
        }
    }
    .foot_contact {
        width: 100%;
        width: 33.33%;
        float: left;
        background: url(../img/line3.jpg) repeat-y right 20px;
        .f_text {
            p {
                margin-bottom: 20px;
                img {}
                em {
                    color: #abacad;
                    font-size: 14px;
                    padding-left: 45px;
                    line-height: 40px;
                    font-style: normal;
                    display: block;
                }
            }
        }
    }
    .foot_pro {
        width: 33.33%;
        float: left;
        padding-left: 50px;
        .f_pronav {
            a {
                color: #abacad;
                font-size: 14px;
                line-height: 30px;
                width: 50%;
                float: left;
            }
        }
    }
    .foot_ewm {
        width: 33.33%;
        padding-left: 60px;
        float: left;
        background: url(../img/line3.jpg) repeat-y left 20px;
        img {
            display: block;
        }
        p {
            margin-top: 10px;
            margin-left: 4px;
            color: #abacad;
            font-size: 16px;
        }
    }
}

.copyright {
    width: 100%;
    position: relative;
    background: #dc323b;
    padding: 10px 0;
    p {
        font-size: 14px;
        color: #fff;
        display: block;
        line-height: 24px;
        text-align: center;
        a {
            font-size: 14px;
            color: #fff;
        }
    }
}

.m_bottom {
    display: none;
    padding-bottom: 75px;
}

.m_fixedmenu {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 110;
    a {
        padding: 8px 0;
        width: 25%;
        float: left;
        background: #333;
        img {
            height: 25px;
            display: block;
            margin: 0 auto;
        }
        span {
            display: block;
            text-align: center;
            font-size: 16px;
            color: #fff;
            margin-top: 12px;
        }
        &:nth-child(2n){
            background: #434343;
        }
    }
}

.page_banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    .pcimg {
        width: 100%;
    }
    .wapimg {
        display: none;
        max-width: 100%;
        height: auto;
    }
}

.pro_box {
    padding: 50px 0;
    background: none;
    margin-top: 0;
    .main_l {
        .text ul {
            background: #f1f1f1;
            li {
                padding-right: 60px;
                border-bottom: 1px solid #e3e3e3;
                background: url(../img/cpyj.png) no-repeat 95% center #f1f1f1;
                a {
                    background: none;
                    text-indent: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    border: none;
                }
                &:hover {
                    background: url(../img/cpyj.png) no-repeat 95% center #ddd;
                }
            }
            .v_list {
                display: none;
                a {
                    display: block;
                    font-size: 14px;
                    width: 100%;
                    line-height: 36px;
                    color: #999;
                    display: block;
                    padding-left: 50px;
                    float: none;
                    border-bottom: 1px dashed #e3e3e3;
                }
            }
        }
        .main_contact .cont_contact {
            background: #f1f1f1;
        }
    }
    .main_r {

    }
    .box1_list {
        margin-top: 10px;
        ul li {
            width: 30%;
            margin: 1.66%;
        }
    }
}

.currson {
    width: 97%;
    margin: 0 1.5%;
    height: 40px;
    background: #f1f1f1;
    border: 1px solid #ddd;
    padding: 0 2%;
    i {
        float: left;
        font-style: normal;
        font-size: 16px;
        color: #666;
        line-height: 40px;

    }
    p {
        float: right;
        line-height: 40px;
        span {
            font-size: 14px;
            color: #999;
        }
        a {
            font-size: 14px;
            color: #999;
            line-height: 110%;
            &:hover {
                color: #dc323b;
            }
        }
    }
}

.container {
    .currson {
        width: 100%;
        margin: 0;
    }
    .pro_box .box1_list {
        position: relative;
        width: 103%;
        left: -1.5%;
    }
}

.new_list {
    width: 100%;
    margin-top: 30px;
    ul {
        li {
            width: 97%;
            margin: 0 1.5%;
            padding: 15px;
            float: left;
            margin-bottom: 20px;
            padding-bottom: 20px;
            box-shadow: 0px 1px 10px 1px #ddd;
            .fl {
                width: 30%;
                .imgauto {
                    width: 100%;
                    height: 185px;
                }
            }
            .fr {
                width: 70%;
                padding-left: 20px;
                span {
                    font-size: 18px;
                    color: #000;
                    display: block;
                    padding-top: 8px;
                }
                p {
                    font-size: 14px;
                    color: #888;
                    display: block;
                    margin-top: 10px;
                    line-height: 24px;
                    height: 96px;
                    overflow: hidden;
                }
                
                .time {
                    padding-left: 20px;
                    font-size: 14px;
                    color: #333;
                    line-height: 30px;
                    background: url(../img/time.png) no-repeat left center;
                    background-size: 16px;
                    margin-top: 10px;
                }
            }
            &:hover {
                box-shadow: 0px 1px 10px 1px #434343;
                .fr {
                    span {
                        color: #dc323b;
                    }
                }
            }
        }
    }
}

.pa_content {
    border: 1px solid #ddd;
    .pa_tit {
        text-align: center;
        display: none;
    }
}

.link {
    width: 100%;
    padding-bottom: 30px;
    .t_tit {
        height: 60px;
        background: url(../img/xw1.jpg) no-repeat 0 bottom;
        margin-bottom: 35px;
        span {
            line-height: 50px;
            font-weight: bold;
            font-size: 18px;
            float: left;
        }
        a {
            float: right;
            width: 55px;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            color: #666;
            background: url(../img/more.gif) no-repeat right center;
        }
    }
    .list {
        a {
            font-size: 14px;
            color: #666;
            margin-right: 20px;
            margin-bottom: 10px;
            float: left;
            &:hover {
                color: #dc323b;
            }
        }
    }
}


.wrap {
    width: 100%;
    margin: 10px 0;
    .main_l {
        background: none;
    }
}

.page_menu {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    h3 {
        display: block;
        line-height: 50px;
        padding-left: 20px;
        background: #dc323b;
        font-size: 24px;
        color: #fff;
        font-weight: normal;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    ul {
        width: 100%;
        display: block;
        border: 1px solid #eee;
        li {
            display: block;
            margin: 6px;
            box-shadow: 0px 1px 10px 1px #ddd;
            a {
                font-size: 14px;
                display: block;
                color: #333;
                padding: 8px 20px;
                transition: .3s;
                padding-left: 40px;
            }
            &:hover {
                a {
                    background: #dc323b;
                    color: #fff;
                    transition: .3s;
                }
            }
        }
    }
}

.pro_list {
    margin-top: 12px;
}

.page_tit {
    width: 100%;
    border-bottom: 1px solid #ddd;
    span {
        font-size: 22px;
        color: #dc323b;
        font-weight: bold;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        border-bottom: 3px solid #dc323b;
        padding-bottom: 12px;
        float: left;
    }
    p {
        float: right;
        font-size: 13px;
        color: #666;
        padding-left: 20px;
        line-height: 44px;
        a {
            font-size: 13px;
            color: #666;
            &:hover {
                color: #dc323b;
            }
        }
    }
}

.page_content {
    width: 100%;
    margin-top: 30px;
    .fl {
        width: 46%;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .fr {
        width: 50%;
        span {
            font-size: 14px;
            color: #333;
            display: block;
        }
        input {
            outline: 0;
            display: block;
            padding: 0 10px;
            line-height: 40px;
            width: 100%;
            border: none;
            height: 40px;
            border: 1px solid #ddd;
            margin-top: 10px;
            margin-bottom: 20px;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #dc323b;
            }
        }
        textarea {
            outline: 0;
            width: 100%;
            border: none;
            height: 100px;
            border: 1px solid #ddd;
            padding: 5px 10px;
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #dc323b;
            }
        }
        .btnsub {
            background: #dc323b;
            border-radius: 5px;
            font-size: 14px;
            color: #fff;
            margin-top: 20px;
            text-align: center;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #fff;
            }
        }
    }
}

.yunu-showcase {
  margin-bottom: 8px;
}

.yunu-showcase .yunupor {
  width: 100%;
  float: left;
}

.yunu-showcase .yunu-showcase-pic {
  position: relative;
  width: 100%;
}

.yunu-showcase .yunu-showcase-pic .cover {
  width: 100%;
  height: 320px;
  line-height: 400px;
  padding: 1px;
  overflow: hidden;
}

.yunu-showcase .yunu-showcase-pic .cover img {
    max-width: 100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: block;
}

.yunu-showcase .prev {
  background: url(../img/prole.png) no-repeat 0 0;
  left: 0;
  position: absolute;
  width: 14px;
  height: 27px;
  bottom: 26px;
  z-index: 2;
}

.yunu-showcase .prev:hover {
  background-position: 0 bottom;
}

.yunu-showcase .next {
  background: url(../img/prori.png) no-repeat 0 0;
  right: 0;
  position: absolute;
  width: 14px;
  height: 27px;
  bottom: 26px;
  z-index: 2;
}

.yunu-showcase .next:hover {
  background-position: 0 bottom;
}

.yunu-showcase .showpropic {
  padding: 0 33px;
}

.yunu-showcase .showpropic .bd {
  width: 100%;
  font-size: 0;
  margin-top: 13px;
  overflow: hidden;
}

.yunu-showcase .showpropic .bd .picList li {
  cursor: pointer;
  float: left;
  width: 25%;
  overflow: hidden;
}
.yunu-showcase .showpropic .bd .picList li span {
  border: 1px solid #ddd;
  padding: 10px;
  display: block;
  margin: 2%;
  height: 55px;
}


.yunu-showcase .showpropic .bd .picList li.active span {
  border-color: #dc323b;
}

.yunu-showcase .showpropic .bd .picList li img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.prohot_new {
    width: 100%;
    padding: 6px;
    span {
        font-size: 22px;
        font-weight: normal;
        color: #545454;
        line-height: 40px;
        border-bottom: 1px solid #ebebeb;
        display: block;
        i {
             @include inline-block();
            height: 40px;
            border-bottom: 1px solid #dc323b;
            font-style: normal;
        }
    }
    ul {
        li {
            color: #545455;
            border-bottom: 1px dotted #dddddd;
            background: url(../img/li.png) no-repeat left 15px;
            line-height: 21px;
            padding: 10px 0px;
            padding-left: 14px;
            a {
                font-size: 14px;
                color: #333;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #dc323b;
                }
            }
        }
    }
}

.content_tit {
    width: 100%;
    border-bottom: 1px dashed #e9e9e9;
    padding-bottom: 15px;
    h3 {
        display: block;
        text-align: center;
        font-size: 28px;
        color: #333;
        font-weight: bold;
        margin-top: 20px;
    }
    p {
        width: 100%;
        text-align: center;
        span {
            @include inline-block();
            line-height: 50px;
            margin-right: 10px;
            padding-left: 10px;
            margin-top: 10px;
            color: #5a5a5a;
            line-height: 100%;
            &:first-child {
                border-left: none;
            }
        }
    }
}
.content {
    width: 100%;
    padding: 0 1%;
    padding-bottom: 20px;
    p {
        font-size: 14px;
        color: #666;
        display: block;
        line-height: 24px;
        margin-top: 10px;
    }
    img {
        display: block;
        max-width: 100%;
    }
}
.show_pages {
    width: 100%;
    background: #f5f5f5;
    padding: 0 1%;
    p {
        font-size: 14px;
        color: #666;
        line-height: 50px;
        a {
            font-size: 14px;
            color: #666;
            line-height: 50px;
            &:hover {
                color: #dc323b;
            }
        }
    }
}
.show_text {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 10px;
    h3 {
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 10px;
        span {
            width: 130px;
            height: 40px;
            display: block;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            background: #434343;
        }
    }
    ul {
        li {
            width: 49%;
            float: left;
            padding-right: 2%;
            padding-left: 15px;
            line-height: 30px;
            background: url(../img/dian2.png) no-repeat 5px center;
            a {
                font-size: 14px;
                color: #666;
                float: left;
                width: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #dc323b;
                }
            }
            span {
                float: right;
                font-size: 14px;
                color: #999;
            }
            &:nth-child(2n) {
                padding-right: 0;
                float: right;
            }
        }
    }
}
.content_box {
    width: 100%;
    margin-top: 20px;
    .fl {
        width: 50%;
        float: left;
        .pic {
            padding: 1%;
            border: 1px solid #ddd;
        }
    }
    .fr {
        width: 47%;
        float: left;
        padding-left: 3%;
        span {
            margin: 0px;
            padding-top: 5px;
            display: block;
            line-height: 22px;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        p {
            line-height: 45px;
            height: 45px;
            border-bottom: 1px dashed #eee;
            font-size: 14px;
            color: #666;
            i {
                font-style: normal;
                font-size: 22px;
                color: #dc323b;
                font-family: impact;
            }
        }
        a {
            width: 145px;
            height: 40px;
            background: #dc323b;
            font-size: 16px;
            color: #fff;
            display: block;
            text-align: center;
            line-height: 40px;
            margin-top: 20px;
            border-radius: 5px;
        }
    }
}
.show_boxa {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #dc323b;
        }
    }
}
.show_text2 {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #dc323b;
        }
    }
}
.hotpro_list {
    ul {
        li {
            width: 23%;
            margin-left: 2.66%;
            float: left;
            position: relative;
            box-shadow: 0px 1px 10px 1px #ddd;
            .imgauto {
                width: 100%;
                height: 208px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 15px;
                font-weight: bold;
                color: #333;
                margin: 10px 0;
                text-align: center;
                display: block;
            }
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                .imgauto {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                }
            }
        }
    }
}




@media screen and (max-width: 1400px){
    .i_box0 .center {
        width: 100%;
    }
    .i_box5 .box .fl {
        padding: 10px 25px;
    }
}

@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .m_search {
        top: 80px;
    }
    .app_menu {
        top: 80px;
    }
    .m_header .m_head_content span {
        line-height: 80px;
    }
    .m_header .m_head_content {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo img {
        max-height: 100%;
    }
    .m_header .m_head_content p img {
        height: 30px;
        margin-top: 25px;
    }
    .m_header .m_head_content .menu {
        height: 70px;
        width: 70px;
        margin-top: 5px;
        margin-right: 10px;
    }
    .m_header .m_head_content .menu i {
        height: 70px;
        line-height: 70px;
    }
    .app_menu ul li a span {
        font-size: 14px;
        height: 42px;
        line-height: 42px;
    }
    .footer {
        padding: 70px 0;
    }
    .page_banner {
        height: auto;
    }
    .page_banner .pcimg {
        display: none;
    }
    .page_banner .wapimg {
        display: block;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .main_l {
        width: 100%;
    }
    .main_l .main_contact {
        display: none;
    }
    .main_r {
        width: 100%;
        margin-top: 10px;
        padding: 0 5px;
    }
    .page_menu h3 {
        background: url(../img/bottom.png) no-repeat 95% center #dc323b;
    }
    .page_menu h3.on {
        background: url(../img/right.png) no-repeat 95% center #dc323b;
    }
    .page_menu ul {
        display: none;
    }
    .page_banner .banner_text {
        display: none;
    }
    .hot_ci {
        display: none;
    }

    .box_tit {
        p {
            font-size: 70px;
            line-height: 120px;
        }
        span {
            font-size: 24px;
            top: 30px;
            i {
                font-size: 24px;
            }
        }
        em {
            font-size: 16px;
            top: 75px;
        }
    }

    .i_box2 .box {
        ul {
            li {
                width: 50%;
                margin: 0;
                padding: 0 5%;
                .pic {
                    width: 150px;
                    height: 150px;
                    img {}
                }
                p {
                    min-height: 96px;
                    line-height: 24px;
                }
            }
        }
    }
    .i_boxnav {
        display: none;
    }

    .footer {
        padding: 30px 0;
        .foot_contact {
            width: 50%;
            padding: 0 2%;
        }
        .foot_pro {
            display: none;
        }
        .foot_ewm {
            width: 50%;
            background: none;
        }
    }
}


@media screen and (max-width: 768px) {
    .main_l {
        display: none;
    }
    .i_box1 {
        padding: 30px 2%;
    }
    .box1_js .swiper-slide .s_box .text p {
        height: 144px;
        overflow: hidden;
    }

    .box1_list ul li {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 4%;
        &:nth-child(3n){
            margin-right: 4%;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
    }

    .i_box4 {
        padding: 20px 2%;
        .box_tit em {
            display: none;
        }
        .list {
            .text1 {
                width: 100%;
            }
            .text2 {
                padding-left: 0;
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    .i_box5 {
        .box {
            margin-top: 20px;
            .fl {
                width: 100%;
            }
            .fr {
                width: 100%;
            }
        }
    }

    .pro_box {
        padding: 30px 0;
        .main_l {
            display: block;
        }
        .main_r {}
    }

    .currson {
        display: none;
    }

    .pro_box .box1_list ul li {
        width: 48%;
        margin: 1%;
    }

    .container {
        .i_box1 {
            padding-top: 0;
        }
        .pro_box .main_l {
            display: none;
        }
    }

    .content_box {
        margin-top: 0;
        .fl {
            width: 100%;
        }
        .fr {
            width: 100%;
            padding: 0;
        }
    }
            
    .yunu-showcase .yunu-showcase-pic .cover {
        height: 240px;
        line-height: 240px;
    }

    .container {
        .pro_box .box1_list {
            position: relative;
            width: 100%;
            left: 0;
        }
    }

    .new_list ul li {
        width: 100%;
        margin: 0;
        padding: 8px;
        margin-bottom: 4%;
        .fl .imgauto {
            height: .8rem;
        }
        .fr {
            span {
                font-size: .12rem;
                padding-top: 0;
            }
            p {
                font-size: .1rem;
                line-height: .16rem;
                height: .32rem;
                overflow: hidden;
                margin-top: .05rem;
            }
            .time {
                font-size: .105rem;
            }
        }
    }
    .pa_content {
        border: none;
        .pa_tit {
            text-align: center;
            display: block;
            padding-bottom: 10px;
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 450px) {
    .box_tit {
        p {
            font-size: .5rem;
            line-height: .65rem;
        }
        span {
            font-size: .16rem;
            top: .1rem;
            i {
                font-size: .16rem;
            }
        }
        em {
            font-size: .11rem;
            top: .45rem;
        }
    }

    .i_box2 {
        padding-top: .15rem;
        .box {
            margin-top: .15rem;
            ul {
                li {
                    width: 50%;
                    margin: 0;
                    padding: 0 5%;
                    .pic {
                        width: 80px;
                        height: 80px;
                    }
                    span {
                        margin-top: .1rem;
                        font-size: .12rem;
                        line-height: .26rem;
                        padding-bottom: .03rem;
                        background-size: .2rem;
                        background: none;
                    }
                    p {
                        display: none;
                    }
                }
            }
        }
    }

    .i_box1 {
        padding: .15rem 2%;
        margin-top: 0;
    }

    .box1_js {
        display: none;
    }

    .box1_list {
        margin-top: .15rem;
        ul li {
            .imgauto {
                height: 1.3rem;
            }
        }
    }

    .i_box0 {
        padding-bottom: .23rem;
    }

    .box0_js {
        margin-top: .2rem;
        padding: 0 50px;
        .swiper-slide .pic .imgauto {
            height: 1.8rem;
        }
        .next {
            width: 40px;
            left: 5px;
            height: 40px;
            margin-top: -20px;
            background-size: 100% 100%;
        }
        .prev {
            width: 40px;
            right: 5px;
            margin-top: -20px;
            height: 40px;
            background-size: 100% 100%;
        }
    }

    .i_box4 {
        padding: .1rem 2%; 
        .list {
            margin-top: .05rem;
            .t_tit {
                height: .3rem;
                margin-bottom: .1rem;
                span {
                    line-height: .2rem;
                    font-size: .13rem;
                }
                a {
                    line-height: .2rem;
                    font-size: .11rem;
                    height: .2rem;
                }
            }
            .t_box {
                .fl {
                    width: 100%;
                    padding: 0;
                    img {
                        width: 100%;
                    }
                }
                .fr {
                    display: none;
                }
            }
            .gs_text {
                span {
                    font-size: .115rem;
                    height: .32rem;
                    line-height: .32rem;
                }
                p {
                    margin: .05rem 0;
                    font-size: .1rem;
                    line-height: .18rem;
                    height: auto;
                }
                i {
                    margin-top: .1rem;
                }
            }
        }
        .que_list ul li {
            margin-bottom: .15rem;
        }
    }

    .i_box5 {
        margin-top: .15rem;
        .box {
            margin-top: .1rem;
            padding: 10px 2%;
            .fl {
                width: 100%;
                padding: 0;
                h3 {
                    font-size: .14rem;
                    height: .36rem;
                    line-height: .36rem;
                    margin-bottom: .1rem;
                    background-size: .3rem;
                }
                p {
                    font-size: .1rem;
                    line-height: .2rem;
                    margin-top: .05rem;
                }
                a {
                    margin-top: .1rem;
                }
            }
            .fr {
                width: 100%;
                padding-top: .1rem;
            }
        }
    }

    .i_box6 {
        padding: .15rem 2%;
        .t_tit {
            height: .3rem;
            margin-bottom: .1rem;
            span {
                line-height: .2rem;
                font-size: .13rem;
            }
            a {
                line-height: .2rem;
                font-size: .11rem;
                height: .2rem;
            }
        }
        .box {
            .swiper-slide {
                padding: 0;
                .imgauto {
                    height: 1.9rem;
                    padding: 10px;
                }
            }
        }
    }

    .footer {
        padding: .15rem 0;
        .foot_tit {
            span {
                font-size: .14rem;
            }
            i {
                font-size: .105rem;
            }
        }
        .foot_contact {
            width: 100%;
            padding: 0 2%;
            .f_text p {
                margin-bottom: .1rem;
                img {
                    height: .34rem;
                }
                em {
                    padding-left: .3rem;
                    font-size: .105rem;
                    line-height: .26rem;
                }
            }
        }
        .foot_pro {
            display: none;
        }
        .foot_ewm {
            width: 100%;
            background: none;
            padding: 0 2%;
            .foot_tit {
                display: none;
            }
            img {
                max-width: .8rem;
                margin-left: .3rem;
            }
            p {
                margin-left: .3rem;
                font-size: .105rem;
                line-height: .26rem;
            }
        }
        .foot_tit {
            margin-bottom: .1rem;
        }
    }

    .copyright {
        padding: .03rem 5%;
        p {
            font-size: .105rem;
            line-height: .2rem;
        }
    }

    .m_bottom{
        display: block;
    }
    .m_fixedmenu {
        display: block;
    }

    .pro_box .box1_list ul li {
        width: 48%;
        margin: 0;
        margin-right: 4%;
        margin-bottom: 4%;
        &:nth-child(2n){
            margin-right: 0;
        }
    }

    .pro_box {
        .main_l {
            padding-bottom: .02rem;
            .text ul {
                li {
                    a {
                        height: 40px;
                        line-height: 40px;
                        font-size: 14px;
                    }
                }
                .v_list {
                    a {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .ml_tit {
        height: auto;
        padding: .05rem 0;
        span {
            font-size: .14rem;
        }
        p {
            font-size: .105rem;
            margin-top: .03rem;
        }
    }
    .content_box {
        .fr {
            margin-top: .1rem;
            p {
                font-size: .105rem;
                height: .3rem;
                line-height: .3rem;
            }
        }
    }

    .show_pages p {
        float: left;
        line-height: 40px!important;
    }
    .show_text ul li {
        width: 100%;
        padding-right: 0;
    }

    .content_tit {
        h3 {
            font-size: .16rem;
            margin-top: .05rem;
        }
        p {
            span {
                font-size: .1rem;
            }
        }
    }

    .content {
        p {
            font-size: .1rem;
            line-height: .19rem;
        }
    }

    .pa_content {
        margin-top: 0;
        .pa_tit {
            font-size: .14rem;
            padding-bottom: .05rem;
        }
    }
    .link {
        padding: .15rem 2%;
        .t_tit {
            height: .3rem;
            margin-bottom: .1rem;
            span {
                line-height: .2rem;
                font-size: .13rem;
            }
            a {
                line-height: .2rem;
                font-size: .11rem;
                height: .2rem;
            }
        }
    }

}

@media screen and (max-width: 350px) {


}

/****************************** media ******************************/